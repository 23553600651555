import React, { useEffect } from "react"
import LayoutComponent from "../components/LayoutComponent/LayoutComponent"
import LinkList from "../components/LinkList/LinkList"
import MenuComponent from "../components/MenuComponent/MenuComponent"
import "../styles/global.scss"
import DerbyHowTo from "../assets/image/HowToRollerderbyEN.png"
import { Link } from "gatsby"
import { unloadRecaptcha } from "../utils/Captcha"
import SEO from "../components/seo"

const Derby = () => {
  useEffect(() => {
    unloadRecaptcha()
  }, [])

  return (
    <LayoutComponent isEnglish languageSwitchSubpage="derby">
      <SEO title="Rollderderby" />
      <MenuComponent
        selected={3}
        isEnglish
        submenu={[
          { title: "Instruction Image", url: "#derby-image" },
          { title: "Game Infotext", url: "#info" },
          { title: "Associations", url: "#verband" },
          { title: "Bundesliga", url: "https://derbyposition.com/de/" },
        ]}
      />

      <h2 className="underline-headline a-little-bit-of-margin-top">
        Rollerderby
      </h2>
      <div className="derby-how-to-image-wrapper" id="derby-image">
        <img
          className="derby-how-to-image"
          src={DerbyHowTo}
          alt="Derby how to"
        />
      </div>
      <p id="info">
        Roller derby is a full contact sport for women and men who have no
        inhibitions about physical contact, requiring skill, tactics and
        fitness. Bumping people is explicitly allowed.
      </p>
      <p>
        It is one of only a few sports where there are no age groups. No matter
        your age, everyone plays together in a team. Gender doesn't matter,
        either, there are mixed teams as well as all-female or all-male's teams.
        We welcome everybody!
      </p>
      <p>
        If you are willing to be engaged in a true worldwide community, no
        matter whether as a player, a skating or non-skating official or as a
        volunteer- this is the right place for you.
      </p>
      <p>So what exactly is Rollerderby?</p>
      <p>
        There is no ball, no rackets, many rules, skates and a lot of action.
      </p>
      <p>Let's start at the beginning.</p>
      <p>
        The game is divided into game units (jams) that run for a maximum of 2
        minutes, then there is a break of 30 seconds, during which the 5 players
        can be exchanged.
      </p>
      <p>
        The playing field is an oval track and takes the place of a basketball
        court.There are 5 players from each team on the playing field (track).
        The 5 consist of: a point collector (jammer) recognizable by the 2 stars
        on the helmet and 4 keepers (blockers).
      </p>
      <p>
        So now the 2 teams with their total of 8 blockers and 2 jammers are on
        the track. The 8 blockers of the teams form a so-called pack. A whistle
        is blown and the jammers try to get through the pack. The jammer who
        made it through the pack first gets the special right to end the jam
        early. The blockers try to clear a path for their jammer and stop the
        opposing jammer at the same time.
      </p>
      <p>
        On the next round, the jammer scores one point for each opponent blocker
        passed.
      </p>
      <p>
        Team size: ca.14 players
        <br />
        Skating referees: approx. 7<br />
        Non-skating referees: approx. 9-11
        <br />
        How long does such a game last? Pure playing time: 2 x 30min
      </p>
      <p>Rules to read and other interesting links:</p>
      <LinkList isEnglish />
      <p>
        Get to know us and this probably fastest growing sport in Germany and
        get caught up in the roller derby fever.
      </p>
      <p>
        The wearing of a full set of protection gear knee and elbow pads, wrist-
        and mouthguard and helmet) while skating is obligatory for all skaters.
        For all who come for the first time we always have some skates and
        protectors left. Every player must own his/her own equipment at the
        latest after joining the club. own equipment.
      </p>

      <h2
        id="training"
        className="underline-headline a-little-bit-of-margin-top"
      >
        Training schedule
      </h2>
      <p>
        <b>Monday</b>
        <br />
        from 6:30 pm - 8:00 pm
        <br />
        in Neckarweihingen,
        <br />
        at the Schwarzwaldhalle, Schwarzwaldstraße 2.
      </p>
      <p>
        <b>Wendnesday</b> (every other week in odd numbered calendar weeks)
        <br />
        from 8:30 pm - 10:00 pm
        <br />
        in Ludwigsburg,
        <br />
        at the gym of the Fröbelschule, Fröbelstraße 24.
      </p>
      <p>
        <b>Thursday</b>
        <br />
        from 7:00 pm - 8:30 pm
        <br />
        in Ludwigsburg,
        <br />
        at the gym of the Fröbelschule, Fröbelstraße 24.
      </p>
      <p>
        <b>Sunday</b> (once in a month)
        <br />
        Not during school holidays.
        <br />
        If you're interested, please ask us about the training time.
      </p>
      <p>
        <b>Here you will find maps of the training venues:</b>
        <br />
        <br />
        <a
          href="https://www.google.de/maps/place/Schwarzwaldhalle+Neckarweihingen/@48.9171669,9.2203281,15z/data=!4m5!3m4!1s0x0:0xd8092b4a63daf715!8m2!3d48.9171669!4d9.2203281"
          target="_blank"
          rel="noreferrer noopener"
        >
          Schwarzwaldhalle Neckarweihingen
        </a>
        <br />
        <a
          href="https://www.google.de/maps/place/48%C2%B054%2720.3%22N+9%C2%B011%2706.2%22E/@48.905253,9.185219,208m/data=!3m1!1e3!4m2!3m1!1s0x0:0x0"
          target="_blank"
          rel="noreferrer noopener"
        >
          Fröbelhalle Ludwigsburg
        </a>
        <br />
        <br />
        You are interested in joining us or just giving it a try-out in
        training? Then contact us:
        <br />
        <br />
        <a href="mailto:recruiting@barockcity.de">
          recruiting@barockcity.de
        </a>{" "}
        or use our <Link to="/en/contact">contact</Link>
        <br />
        <br />
        The training is conducted professionally!
        <br />
        <br />
        It is required to wear full protective gear while skating (kneepads,
        elbow pads, wristguard, helmet and mouthguard). For a trial practice we
        have skates and protective gear available for you to borrow. When you
        decide to join the team, you will need to get your own gear.
      </p>
    </LayoutComponent>
  )
}

export default Derby
